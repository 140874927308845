import { Button, Col, Flex, Image, Row, Select, Space, Typography } from 'antd';

import { getImageUrl } from '@/shared/utils';
import { DownloadOutlined } from '@ant-design/icons';

export type AppInfoHeaderProps = {
  displayName: string;
  logo: string;
  shortDescription: string;
  selectedChannel: string;
  channels: string[];
  onReleaseChannelChange: (channel: string) => void;
  onDownloadClick: () => void;
};

export const AppInfoHeader = ({
  displayName,
  shortDescription,
  logo,
  selectedChannel,
  channels,
  onReleaseChannelChange,
  onDownloadClick,
}: AppInfoHeaderProps) => {
  return (
    <Row align={'top'}>
      <Col span={'20'}>
        <Flex align={'center'} gap={16}>
          <Image src={getImageUrl(logo)} preview={false} width={80} />
          <Space direction={'vertical'}>
            <Flex vertical={true} justify={'start'}>
              <Typography.Text strong={true}>{displayName}</Typography.Text>
              <Typography.Text type={'secondary'}>{shortDescription}</Typography.Text>
            </Flex>
            <Button type={'primary'} icon={<DownloadOutlined />} onClick={onDownloadClick}>
              Herunterladen
            </Button>
          </Space>
        </Flex>
      </Col>
      <Col span={'4'} style={{ textAlign: 'right' }}>
        <Space>
          <Typography.Text type={'secondary'}>Channel</Typography.Text>
          <Select
            options={channels?.map((c) => ({ value: c, label: c }))}
            onChange={onReleaseChannelChange}
            value={selectedChannel}
          />
        </Space>
      </Col>
    </Row>
  );
};
